import { persistentAtom } from "@nanostores/persistent"
import { Assortment } from "features/rawMaterialCalculator/types"

const persistentKey = "raw-materials-calculator"

const persistentOptions = {
  encode: JSON.stringify,
  decode: JSON.parse,
}

const $assortments = persistentAtom<Assortment[]>(
  `${persistentKey}:assortments`,
  [],
  persistentOptions,
)

const setAssortments = (newAssortments: Assortment[]) => {
  $assortments.set(newAssortments)
}

const deleteAssortment = (index: number) => {
  $assortments.set($assortments.get().filter((_, i) => i !== index))
}

const clearAssortments = () => {
  $assortments.set([])
}

export { $assortments, setAssortments, deleteAssortment, clearAssortments }
