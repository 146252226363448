import { Step2Metrics } from "features/rawMaterialCalculator/types"

type TExtraMetrics = {
  creamFat: number
  freeFatMilkFat: number
  rawMilkFat: number
}

/** @description корректируем кол-во имеющихся сливок */
const correctCurrentCream = (state: Step2Metrics) => {
  return {
    ...state,
    creamCurrentWeight: state.creamCurrentWeight - state.creamWeightNeedWeight,
  }
}

/** @description доп. сепарирование для получения сливок */
const separateToGetCream = (
  state: Step2Metrics,
  { creamFat, freeFatMilkFat, rawMilkFat }: TExtraMetrics,
) => {
  const creamAdditionalSeparationWeight = state.creamWeightNeedWeight - state.creamCurrentWeight
  const rawMilkAdditionalSeparationNeedWeight =
    (creamAdditionalSeparationWeight * (creamFat - freeFatMilkFat)) / (rawMilkFat - freeFatMilkFat)

  const rawMilkNeedWeight = state.rawMilkNeedWeight + rawMilkAdditionalSeparationNeedWeight
  const creamOutputWeight = state.creamOutputWeight + creamAdditionalSeparationWeight

  const creamCurrentWeight = 0

  const freeFatMilkAdditionalSeparationWeight =
    rawMilkAdditionalSeparationNeedWeight - creamAdditionalSeparationWeight

  const freeFatMilkCurrentWeight =
    state.freeFatMilkCurrentWeight + freeFatMilkAdditionalSeparationWeight
  const freeFatMilkOutputWeight =
    state.freeFatMilkOutputWeight + freeFatMilkAdditionalSeparationWeight

  return {
    ...state,
    rawMilkNeedWeight,
    creamOutputWeight,
    creamCurrentWeight,
    freeFatMilkCurrentWeight,
    freeFatMilkOutputWeight,
  }
}

/**
 * @description корректируем кол-во имеющихся сливок или сепарируем для получения сливок
 */
const correctCreamOrSeparate = (state: Step2Metrics, metrics: TExtraMetrics) => {
  if (state.creamCurrentWeight >= state.creamWeightNeedWeight) {
    return correctCurrentCream(state)
  } else {
    return separateToGetCream(state, metrics)
  }
}

/** @description корректируем кол-во имеющегося обрата */
const correctCurrentFreeFatMilk = (state: Step2Metrics) => {
  return {
    ...state,
    freeFatMilkCurrentWeight: state.freeFatMilkCurrentWeight - state.freeFatMilkNeedWeight,
  }
}

/** @description доп. сепарирование для получения обрата */
const calculateToGetFreeFatMilk = (
  state: Step2Metrics,
  { creamFat, freeFatMilkFat, rawMilkFat }: TExtraMetrics,
) => {
  const deltaFreeFatMilkVolumeForLowFatProducts =
    state.freeFatMilkNeedWeight - state.freeFatMilkCurrentWeight

  const deltaRawMilkNeedForLowFatProducts =
    (deltaFreeFatMilkVolumeForLowFatProducts * (creamFat - freeFatMilkFat)) /
    (creamFat - rawMilkFat)

  const rawMilkNeedWeight = state.rawMilkNeedWeight + deltaRawMilkNeedForLowFatProducts
  const freeFatMilkOutputWeight =
    state.freeFatMilkOutputWeight + deltaFreeFatMilkVolumeForLowFatProducts

  const freeFatMilkCurrentWeight = 0

  const deltaCreamFromLowFatProducts =
    deltaRawMilkNeedForLowFatProducts - deltaFreeFatMilkVolumeForLowFatProducts

  const creamCurrentWeight = state.creamCurrentWeight + deltaCreamFromLowFatProducts
  const creamOutputWeight = state.creamOutputWeight + deltaCreamFromLowFatProducts

  return {
    ...state,
    rawMilkNeedWeight,
    freeFatMilkOutputWeight,
    freeFatMilkCurrentWeight,
    creamCurrentWeight,
    creamOutputWeight,
  }
}

/**
 * @description корректируем кол-во имеющегося обрата или сепарируем для получения обрата
 */
const correctFreeFatMilkOrSeparate = (state: Step2Metrics, metrics: TExtraMetrics) => {
  if (state.freeFatMilkCurrentWeight >= state.freeFatMilkNeedWeight) {
    return correctCurrentFreeFatMilk(state)
  } else {
    return calculateToGetFreeFatMilk(state, metrics)
  }
}

/**  @description корректируем и сепарируем сливки и обрат */
export const correctOrSeparateCreamAndFreeFatMilk = (
  state: Step2Metrics,
  metrics: TExtraMetrics,
) => {
  const correctedState = correctCreamOrSeparate(state, metrics)
  const correctedAndSeparatedState = correctFreeFatMilkOrSeparate(correctedState, metrics)
  return correctedAndSeparatedState
}
