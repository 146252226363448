import { Calculate as TCalculationResult } from "features/rawMaterialCalculator/types"
import { atom } from "nanostores"

import { calculateRawMaterials } from "../lib/calculation/calculate"
import { streamToVolumeConfigs, volumeToStreamConfigs } from "../lib/utils/configs"
import { $assortments } from "./assortments"
import { $inputData } from "./inputData"

const initialValues: TCalculationResult = {
  rawMilkNeedWeight: 0,
  rawMilkQuantity: 0,
  creamOutputWeight: 0,
  creamCurrentWeight: 0,
  freeFatMilkOutputWeight: 0,
  freeFatMilkCurrentWeight: 0,
  cottageCheeseWheyWeight: 0,
  cheeseWheyWeight: 0,
  butterMilkWeightFromHiFatProducts: 0,
}

const $result = atom<TCalculationResult>(initialValues)
const $reversedResult = atom<TCalculationResult>(initialValues)
const $errors = atom<string[]>([])

const setErrors = (newErrors: string[]) => {
  $errors.set(newErrors)
}

const clearErrors = () => {
  $errors.set([])
}

const doCalculate = () => {
  const { errors, ...result } = calculateRawMaterials(
    $inputData.get(),
    $assortments.get(),
    streamToVolumeConfigs,
  )

  if (errors.length > 0) {
    setErrors([...new Set(errors)])
  } else {
    $result.set(result)
  }
}

const doReversedCalculate = () => {
  const { errors, ...result } = calculateRawMaterials(
    $inputData.get(),
    $assortments.get(),
    volumeToStreamConfigs,
  )
  if (errors.length === 0) {
    $reversedResult.set(result)
  }
}

const clearCalculation = () => {
  $result.set(initialValues)
  $reversedResult.set(initialValues)
}

export {
  $result,
  $reversedResult,
  $errors,
  doCalculate,
  doReversedCalculate,
  clearCalculation,
  clearErrors,
}
