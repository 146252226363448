import { DairyProcessingMetrics } from "features/rawMaterialCalculator/types"

export const calculateForHiFatProductsInStream = ({
  state,
  butterMilkFat,
  _currentFat,
  _currentWeight,
  creamFat,
}: {
  state: DairyProcessingMetrics
  butterMilkFat: number
  _currentFat: number
  _currentWeight: number
  creamFat: number
}) => {
  const creamWeightIteration =
    (_currentWeight * (_currentFat - butterMilkFat)) / (creamFat - butterMilkFat)
  const butterMilkWeightIteration = creamWeightIteration - _currentWeight

  return {
    ...state,
    creamWeightIteration,
    butterMilkWeightIteration,
    creamWeightForHiFatProducts: state.creamWeightForHiFatProducts + creamWeightIteration,
    butterMilkWeightFromHiFatProducts:
      state.butterMilkWeightFromHiFatProducts + butterMilkWeightIteration,
  }
}
