import React, { useMemo } from "react"
import { useLocation } from "react-router-dom"

import { ForkOutlined, PartitionOutlined, BuildOutlined, UserOutlined } from "@ant-design/icons"
import { useStore } from "@nanostores/react"
import { Button, Layout, Modal, Space, Typography } from "antd"
import { rawMaterialCalculator } from "features/rawMaterialCalculator"
import { adminModuleRoutes, clientModuleRoutes } from "shared/constants/routes"
import { MenuLayout } from "shared/layouts/MenuLayout"
import { MainHeader } from "shared/ui/MainHeader"

const { Title } = Typography

const menuAdminItems: {
  label: string
  key: React.Key
  icon?: React.ReactNode
  appendSeparator?: boolean
}[] = [
  {
    label: adminModuleRoutes["product-types"].title,
    key: adminModuleRoutes["product-types"].root,
    icon: <PartitionOutlined />,
  },
  {
    label: adminModuleRoutes.mixes.title,
    key: adminModuleRoutes.mixes.root,
    icon: <ForkOutlined />,
  },
  {
    label: adminModuleRoutes["finished-products"].title,
    key: adminModuleRoutes["finished-products"].root,
    icon: <BuildOutlined />,
    appendSeparator: true,
  },

  {
    label: adminModuleRoutes.users.title,
    key: adminModuleRoutes.users.root,
    icon: <UserOutlined />,
  },
]

type MainLayoutProps = {
  children?: React.ReactNode
}
export const MainLayout = ({ children }: MainLayoutProps) => {
  const changeConfirmModal = useStore(rawMaterialCalculator.$warnModal)

  const location = useLocation()

  const moduleType = location.pathname.includes("admin") ? "admin" : "client"

  const rawMaterialAssortments = useStore(rawMaterialCalculator.$assortments)

  // const clientKey = location.pathname.includes("raw-materials") ? "rawMaterials" : "recipes"

  const clientKey = "rawMaterials"

  const clientModuleConfig = {
    rawMaterials: {
      routeKey: "rawMaterials",
      data: rawMaterialAssortments,
    },
  } as const

  const currentInfo = clientModuleConfig[clientKey]

  const menuClientItems: {
    label: string
    key: React.Key
    icon?: React.ReactNode
    disable?: boolean
  }[] = useMemo(() => {
    return [
      {
        label: clientModuleRoutes[currentInfo.routeKey].assortment.title,
        key: clientModuleRoutes[currentInfo.routeKey].assortment.root,
      },
      {
        label: clientModuleRoutes[currentInfo.routeKey].calculator.title,
        key: clientModuleRoutes[currentInfo.routeKey].calculator.root,
        disable: !currentInfo.data?.length,
      },
    ]
  }, [currentInfo])

  const menuItems = useMemo(() => {
    return moduleType ? (moduleType === "admin" ? menuAdminItems : menuClientItems) : []
  }, [moduleType, menuClientItems])

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <MainHeader />
      <MenuLayout menuItems={menuItems}>{children}</MenuLayout>
      <Modal
        title='Подтверждение действия'
        centered
        open={!!changeConfirmModal}
        onOk={() => rawMaterialCalculator.updateWarnModal()}
        onCancel={() => rawMaterialCalculator.updateWarnModal()}
        footer={false}
      >
        <Space direction='vertical'>
          <Title level={5} style={{ marginBottom: 30 }}>
            {changeConfirmModal?.desc}
          </Title>
          <Space direction='horizontal'>
            <Button
              onClick={() => {
                changeConfirmModal?.confirm()
                rawMaterialCalculator.updateWarnModal()
              }}
              type='primary'
              htmlType='submit'
            >
              Подтвердить
            </Button>
            <Button onClick={() => rawMaterialCalculator.updateWarnModal()} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
    </Layout>
  )
}
