import { Navigate, createBrowserRouter } from "react-router-dom"

import { LoginPage } from "pages/Login"
import { Mixes } from "pages/admin/Mixes/Mixes"
import { Products } from "pages/admin/Products"
import { ProductsTypes } from "pages/admin/ProductsTypes"
import { Users } from "pages/admin/Users"
import { RawMaterialAssortment } from "pages/client/RawMaterialAssortment"
import { RawMaterialCalculator } from "pages/client/RawMaterialCalculator"
import { adminModuleRoutes, clientModuleRoutes } from "shared/constants/routes"
import { modulesRoutes } from "shared/constants/routes/modulesRoutes"
import { MainLayout } from "shared/layouts/MainLayout"

import { RequireAdminRoute } from "./RequireAdminRoute"
import { RequireAuthRoute } from "./RequireAuthRoute"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={clientModuleRoutes.rawMaterials.assortment.root} replace={false} />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    element: (
      <MainLayout>
        <RequireAuthRoute />
      </MainLayout>
    ),
    children: [
      {
        path: clientModuleRoutes.rawMaterials.assortment.root,
        element: <RawMaterialAssortment />,
      },
      {
        path: clientModuleRoutes.rawMaterials.calculator.root,
        element: <RawMaterialCalculator />,
      },
      {
        path: `${clientModuleRoutes.root}/*`,
        element: <Navigate to={clientModuleRoutes.rawMaterials.assortment.root} replace={false} />,
      },
      {
        element: <RequireAdminRoute />,
        children: [
          {
            path: adminModuleRoutes["finished-products"].root,
            element: <Products />,
          },
          {
            path: adminModuleRoutes["product-types"].root,
            element: <ProductsTypes />,
          },
          {
            path: adminModuleRoutes.mixes.root,
            element: <Mixes />,
          },
          {
            path: `${adminModuleRoutes.root}/*`,
            element: <Navigate to={adminModuleRoutes["finished-products"].root} replace={false} />,
          },
          {
            path: `${adminModuleRoutes.users.root}/*`,
            element: <Users />,
          },
        ],
      },
      {
        path: `${modulesRoutes.root}/*`,
        element: <Navigate to={clientModuleRoutes.rawMaterials.assortment.root} replace={false} />,
      },
    ],
  },
])
