import { SystemName } from "api/model"

import { Assortment, NormalizationMethod, SpecialTypes, DairyProcessingMetrics } from "../../types"

export const calculateForSpecialTypes = ({
  state,
  errors,
  systemName,
  _currentWeight,
  currentFatProduct,
  normalizationMethod,
  assortment,
}: {
  state: DairyProcessingMetrics
  errors: string[]
  systemName: SystemName
  _currentWeight: number
  currentFatProduct: number
  normalizationMethod: NormalizationMethod
  assortment: Assortment
}) => {
  const handlerBySpecialType = {
    whey_drinks: () => {
      if (normalizationMethod === "stream") {
        errors.push(
          `${assortment.product.name}, ${currentFatProduct}% (${
            normalizationMethod === "stream" ? "нормализация в потоке" : "объёмная нормализация"
          })`,
        )

        return state
      }

      return {
        ...state,
        wheyWeightForWheyDrinksProducts: state.wheyWeightForWheyDrinksProducts + _currentWeight,
      }
    },
    buttermilk_drinks: () => {
      if (normalizationMethod === "stream") {
        errors.push(
          `${assortment.product.name}, ${currentFatProduct}% (${
            normalizationMethod === "stream" ? "нормализация в потоке" : "объёмная нормализация"
          })`,
        )

        return state
      }

      return {
        ...state,
        butterMilkWeightForButterMilkDrinksProducts:
          state.butterMilkWeightForButterMilkDrinksProducts + _currentWeight,
      }
    },
  }

  return handlerBySpecialType[systemName as SpecialTypes]()
}
