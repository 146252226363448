import { SorterResult } from "antd/es/table/interface"
import { ColumnsType } from "antd/lib/table"

import { CopyOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import { ProductDto } from "api/model"
import { numberToString } from "shared/utils/numberToString"

export const createTableColumns = ({
  sortedInfo,
  isLoading,
  onCopyClick,
  onEditClick,
}: {
  sortedInfo: SorterResult<ProductDto>
  isLoading: boolean
  onCopyClick: (id: number) => void
  onEditClick: (record: ProductDto) => void
}) => {
  const cols: ColumnsType<ProductDto> = [
    {
      title: "Id",
      dataIndex: "mockId",
      key: "id",
      width: 60,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      sorter: (a, b) => {
        if (a.mockId && b.mockId) {
          return a.mockId - b.mockId
        }
        return 0
      },
    },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      width: 280,
    },
    {
      title: "МДЖ %",
      dataIndex: "fatPercentage",
      key: "fatPercentage",
      width: 110,
      sorter: (a, b) => a.fatPercentage - b.fatPercentage,
      sortOrder: sortedInfo.columnKey === "fatPercentage" ? sortedInfo.order : null,
      render: (v) => `${numberToString(v)}%`,
    },
    {
      title: "МДБ %",
      dataIndex: "proteinPercentage",
      key: "proteinPercentage",
      width: 110,
      sorter: (a, b) => Number(a.proteinPercentage) - Number(b.proteinPercentage),
      sortOrder: sortedInfo.columnKey === "proteinPercentage" ? sortedInfo.order : null,
      render: (v) => (v ? `${numberToString(v)}%` : "-"),
    },
    {
      title: "Тип продукта",
      dataIndex: "type",
      key: "type",
      render: (_, record) => {
        return <>{record.type.name}</>
      },
      sorter: (a, b) => a.type.name.localeCompare(b.type.name),
      sortOrder: sortedInfo.columnKey === "type" ? sortedInfo.order : null,
    },
    {
      title: "Смесь",
      dataIndex: "mix",
      key: "type",
      render: (_, record) => {
        return <>{record.mix?.name}</>
      },
    },
    {
      title: "Действие",
      key: "action",
      width: 180,
      render: (_, record) => {
        return (
          <Space.Compact style={{ gap: "8px" }} direction='vertical'>
            <Button
              style={{ width: "100%" }}
              size='small'
              loading={isLoading}
              onClick={() => onEditClick(record)}
              type='primary'
              icon={<EditOutlined />}
            >
              Изменить
            </Button>

            <Button
              style={{ width: "100%" }}
              size='small'
              loading={isLoading}
              onClick={() => onCopyClick(record.id)}
              icon={<CopyOutlined />}
            >
              Копировать
            </Button>
          </Space.Compact>
        )
      },
    },
  ]

  return cols
}
