import { useEffect, useRef } from "react"
import { Navigate, Outlet } from "react-router-dom"

import { useStore } from "@nanostores/react"
import { notification } from "antd"
import { authModel } from "features/auth"
import { clientModuleRoutes } from "shared/constants/routes"

export function RequireAdminRoute() {
  const isAdmin = useStore(authModel.$isAdmin)
  const onceRef = useRef(false)

  useEffect(() => {
    if (!isAdmin && !onceRef.current) {
      notification.error({
        message: "Ошибка доступа",
        description: "У вас нет прав доступа к этому разделу",
      })
      onceRef.current = true
    }
  }, [isAdmin, onceRef])

  if (!isAdmin) {
    return <Navigate to={clientModuleRoutes.rawMaterials.assortment.root} />
  }

  return <Outlet />
}
