import { Descriptions, Space, Typography, Row, Col } from "antd"
import { ExportCSVButton } from "features/exportCSV"
import { Calculate } from "features/rawMaterialCalculator/types"
import { formatDateAndTime } from "shared/utils/dateTime"
import { validateAndRoundNumberForResult } from "shared/utils/validateAndRoundNumber"

const { Title } = Typography

export const CalculationResults = ({
  data,
  title,
  dataForCSV,
}: {
  data?: Calculate
  title: string
  dataForCSV: (string | number)[][]
}) => {
  return (
    <>
      <Row align='middle' gutter={[24, 24]} style={{ marginBottom: "24px" }}>
        <Col>
          <Title style={{ marginBottom: "4px" }} level={3}>
            {title}
          </Title>
        </Col>
        <Col>
          <ExportCSVButton
            dataForExportCSV={dataForCSV}
            fileName={`${title} ${formatDateAndTime(new Date())}`}
          />
        </Col>
      </Row>

      <Row style={{ maxWidth: "600px" }}>
        <Col span={14}>
          <Space direction='vertical'>
            <Descriptions column={1} size='small' title='Баланс:'>
              <Descriptions.Item label='Расход сырого молока, кг'>
                {validateAndRoundNumberForResult(data?.rawMilkNeedWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Остаток сырого молока, кг'>
                {validateAndRoundNumberForResult(data?.rawMilkQuantity)}
              </Descriptions.Item>
              <br />
              <Descriptions.Item label='Выход сливок, кг'>
                {validateAndRoundNumberForResult(data?.creamOutputWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Остаток сливок, кг'>
                {validateAndRoundNumberForResult(data?.creamCurrentWeight)}
              </Descriptions.Item>
              <br />
              <Descriptions.Item label='Выход обрата, кг'>
                {validateAndRoundNumberForResult(data?.freeFatMilkOutputWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Остаток обрата, кг'>
                {validateAndRoundNumberForResult(data?.freeFatMilkCurrentWeight)}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </Col>
        <Col span={10}>
          <Space direction='vertical'>
            <Descriptions column={1} size='small' title='Вторичное сырьё:'>
              <Descriptions.Item label='Творожная сыворотка, кг'>
                {validateAndRoundNumberForResult(data?.cottageCheeseWheyWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Подсырная сыворотка, кг'>
                {validateAndRoundNumberForResult(data?.cheeseWheyWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Пахта, кг'>
                {validateAndRoundNumberForResult(data?.butterMilkWeightFromHiFatProducts)}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </Col>
      </Row>
    </>
  )
}
