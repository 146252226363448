import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { CalculatorOutlined, CaretLeftOutlined } from "@ant-design/icons"
import { useStore } from "@nanostores/react"
import { Button, Form, InputNumber, Space, Typography } from "antd"
import { createCSVData } from "features/exportCSV/lib/createCSVData"
import { rawMaterialCalculator } from "features/rawMaterialCalculator/model"
import { clientModuleRoutes } from "shared/constants/routes"
import { ErrorCard } from "shared/ui/ErrorCard"

import { CalculationResults } from "../../../features/rawMaterialCalculator/ui/CalculationResults"
import styles from "./RawMaterialCalculator.module.scss"

const { Title } = Typography
const { Item } = Form

export const RawMaterialCalculator = () => {
  const result = useStore(rawMaterialCalculator.$result)
  const reversedResult = useStore(rawMaterialCalculator.$reversedResult)
  const errors = useStore(rawMaterialCalculator.$errors)
  const assortments = useStore(rawMaterialCalculator.$assortments)
  const inputData = useStore(rawMaterialCalculator.$inputData)

  const navigate = useNavigate()

  const [form] = Form.useForm()

  useEffect(() => {
    rawMaterialCalculator.clearInputData()
    rawMaterialCalculator.clearCalculation()
    rawMaterialCalculator.clearErrors()
  }, [])

  const onChange = (event: { name: string; value: number | null }) => {
    rawMaterialCalculator.addInputData({ [event.name]: event.value })
    rawMaterialCalculator.clearCalculation()
    rawMaterialCalculator.clearErrors()
  }

  const onClickCalculate = () => {
    rawMaterialCalculator.doCalculate()
    if (assortments.length > 1) {
      rawMaterialCalculator.doReversedCalculate()
    }
  }

  const shouldDoSecondCalculation = useMemo(() => {
    const hasStreamNormalization = assortments.some((el) => el.normalizationMethod === "stream")
    const hasVolumeNormalization = assortments.some((el) => el.normalizationMethod === "volume")

    return assortments.length > 1 && hasStreamNormalization && hasVolumeNormalization
  }, [assortments])

  const isFreeFatMilkFatIsDisabled = useMemo(() => {
    if (inputData?.rawMilkQuantity && inputData?.rawMilkFat && inputData?.creamFat) {
      return false
    }
    return true
  }, [inputData, assortments])

  const isButterMilkFatIsDisabled = useMemo(() => {
    if (inputData?.rawMilkQuantity && inputData?.rawMilkFat && inputData?.creamFat) {
      const assortmentsFiltered = assortments.filter(
        (assortment) => assortment.product.type.buttermilk,
      )
      return assortmentsFiltered.length === 0
    }
    return true
  }, [inputData, assortments])

  const isCalculationButtonIsDisabled = useMemo(() => {
    const hasRequiredInputData =
      inputData?.rawMilkQuantity && inputData?.rawMilkFat && inputData?.creamFat

    if (!hasRequiredInputData) {
      return true
    }

    if (!isFreeFatMilkFatIsDisabled && !inputData.freeFatMilkFat) {
      return true
    }

    if (!isButterMilkFatIsDisabled && !inputData.butterMilkFat) {
      return true
    }

    return false
  }, [isFreeFatMilkFatIsDisabled, isButterMilkFatIsDisabled, inputData])

  const isFillingInTheFields = useMemo(() => {
    return !!(
      inputData?.rawMilkQuantity ||
      inputData?.rawMilkFat ||
      inputData?.creamFat ||
      inputData?.freeFatMilkFat ||
      inputData?.butterMilkFat ||
      result?.rawMilkNeedWeight ||
      result?.rawMilkQuantity ||
      result?.creamOutputWeight ||
      result?.creamCurrentWeight ||
      result?.freeFatMilkOutputWeight ||
      result?.freeFatMilkCurrentWeight ||
      result?.cottageCheeseWheyWeight ||
      result?.cheeseWheyWeight ||
      result?.butterMilkWeightFromHiFatProducts
    )
  }, [inputData, result])

  return (
    <Space direction='vertical'>
      <Space>
        <Title level={2}>Расчет сырья</Title>
      </Space>
      <Space direction='vertical'>
        <Title level={3}>Исходные данные</Title>
        <Form form={form} className={styles.input_data_form}>
          <Form labelCol={{ span: 9 }} layout='horizontal'>
            <Title level={4}>Сырое молоко:</Title>
            <Space direction='vertical'>
              <Item label='Количество, кг' required>
                <InputNumber
                  style={{ width: 200 }}
                  name='rawMilkQuantity'
                  onChange={(value) => onChange({ name: "rawMilkQuantity", value })}
                  value={inputData.rawMilkQuantity}
                  placeholder='Количество, кг'
                  decimalSeparator=','
                  precision={2}
                  min={0}
                />
              </Item>
              <Item label='МДЖ, %' required>
                <InputNumber
                  style={{ width: 200 }}
                  name='rawMilkFat'
                  onChange={(value) => onChange({ name: "rawMilkFat", value })}
                  value={inputData.rawMilkFat}
                  placeholder='МДЖ, %'
                  decimalSeparator=','
                  precision={2}
                  max={10}
                  min={0}
                />
              </Item>
            </Space>
          </Form>
          <Form layout='horizontal' labelCol={{ span: 14 }}>
            <Title level={4}>Параметры:</Title>
            <Item label='МДЖ сепарированных сливок, %' required>
              <InputNumber
                style={{ width: 200 }}
                name='creamFat'
                onChange={(value) => onChange({ name: "creamFat", value })}
                value={inputData.creamFat}
                placeholder='МДЖ сепарированных сливок, %'
                decimalSeparator=','
                max={65}
                precision={2}
                min={0}
              />
            </Item>
            <Item label='МДЖ обрата, %' required={!isFreeFatMilkFatIsDisabled}>
              <InputNumber
                style={{ width: 200 }}
                name='freeFatMilkFat'
                onChange={(value) => onChange({ name: "freeFatMilkFat", value })}
                value={inputData.freeFatMilkFat}
                placeholder='МДЖ обрата, %'
                decimalSeparator=','
                precision={2}
                max={1.5}
                disabled={isFreeFatMilkFatIsDisabled}
              />
            </Item>
            <Item label='МДЖ пахты, %' required={!isButterMilkFatIsDisabled}>
              <InputNumber
                style={{ width: 200 }}
                name='butterMilkFat'
                onChange={(value) => onChange({ name: "butterMilkFat", value })}
                value={inputData.butterMilkFat}
                placeholder='МДЖ пахты, %'
                decimalSeparator=','
                precision={2}
                max={4}
                disabled={isButterMilkFatIsDisabled}
                min={0.01}
              />
            </Item>
          </Form>
        </Form>
        <Button
          onClick={onClickCalculate}
          style={{ marginTop: 30, marginBottom: 30 }}
          type='primary'
          icon={<CalculatorOutlined />}
          htmlType='submit'
          disabled={isCalculationButtonIsDisabled}
        >
          Произвести расчёт
        </Button>
      </Space>
      {errors.length > 0 && (
        <>
          <ErrorCard errors={errors} />
          <br />
        </>
      )}
      <CalculationResults
        dataForCSV={createCSVData({ calcData: result, inputData, assortments })}
        title={`Результат расчета${shouldDoSecondCalculation ? " (поток → объем)" : ""} `}
        data={result}
      />
      {shouldDoSecondCalculation && (
        <>
          <br />
          <CalculationResults
            dataForCSV={createCSVData({
              calcData: reversedResult,
              inputData,
              assortments,
            })}
            title='Результат расчета (объем → поток)'
            data={reversedResult}
          />
        </>
      )}

      <Space style={{ marginTop: 30 }}>
        <Button
          onClick={() => {
            if (isFillingInTheFields) {
              rawMaterialCalculator.updateWarnModal({
                desc: "Внимание! Введенные данные будут потеряны.",
                confirm: () => {
                  navigate(clientModuleRoutes.rawMaterials.assortment.root)
                },
              })
            } else {
              navigate(clientModuleRoutes.rawMaterials.assortment.root)
            }
          }}
          type='primary'
          icon={<CaretLeftOutlined />}
        >
          Изменить ассортимент
        </Button>
      </Space>
    </Space>
  )
}
