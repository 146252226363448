import { SystemName } from "api/model"
import {
  Assortment,
  DairyProcessingMetrics,
  NormalizationMethod,
  ProductFatType,
  SpecialTypes,
  Step2Metrics,
} from "features/rawMaterialCalculator/types"

import { getProductType, getProductTypeConfig } from "../utils"
import { isSpecialType } from "../utils/predicates"
import { calculateCheeseAndCottage } from "./calculateCheeseAndCottage"
import { calculateForSpecialTypes } from "./calculateForSpecialTypes"
import { getCurrentWeightAndFat } from "./getCurrentWeightAndFat"

function logCalculateAction(method: NormalizationMethod, productType: ProductFatType) {
  console.info(`%cрасчет ${productType} в ${method}`, "color: green")
}

export function loopCalculation(
  {
    config,
    assortments,
    dairyProcessingMetrics,
    inputData,
    step2Metrics,
  }: {
    consumptionField?: "amount" | "lossPercentage"
    inputData: Record<string, number>
    assortments: Assortment[]
    dairyProcessingMetrics: DairyProcessingMetrics
    step2Metrics: Step2Metrics
    config: {
      types: ProductFatType[]
      methods: NormalizationMethod[]
    }
    configIndex: number
  },
  errors: string[],
) {
  const { rawMilkFat, creamFat, freeFatMilkFat: freeFatMilkFatOrig, butterMilkFat = 0 } = inputData

  const localState = {
    step2Metrics,
    dairyProcessingMetrics,
  }

  const freeFatMilkFat =
    freeFatMilkFatOrig === null || freeFatMilkFatOrig === undefined ? 0 : freeFatMilkFatOrig //МДЖ обрата, %

  assortments.forEach((assortment) => {
    const {
      currentWeight,
      normalizationMethod,
      product: {
        fatPercentage: currentFatProduct,
        type: {
          mixConsumption: mixConsumptionEnabled,
          cottageCheeseWhey,
          cheeseWhey,
          buttermilk,
          systemName: _systemName,
        },
      },
    } = assortment

    const systemName = _systemName as SystemName

    const isButter = systemName === "butter"

    const currentFatMix = assortment?.product?.mix?.fatPercentage ?? 0

    const { _currentWeight, _currentFat } = getCurrentWeightAndFat({
      mixConsumptionEnabled,
      currentWeight,
      mixConsumption: assortment.product.mixConsumption,
      currentFatMix,
      currentFatProduct,
      isButter,
    })

    const productType = getProductType({
      _currentFat,
      creamFat,
      freeFatMilkFat,
      rawMilkFat,
      systemName,
    })

    if (isSpecialType(productType)) {
      if (!config.types.includes(productType) || !config.methods.includes(normalizationMethod)) {
        return
      }
      localState.dairyProcessingMetrics = calculateForSpecialTypes({
        state: localState.dairyProcessingMetrics,
        errors,
        systemName: systemName as SpecialTypes,
        _currentWeight,
        currentFatProduct,
        normalizationMethod,
        assortment,
      })

      return
    }

    const isIncorrectMediumFatProduct =
      productType === "mediumFatProduct" &&
      normalizationMethod === "stream" &&
      _currentFat !== creamFat

    const isIncorrectHiFatProduct = productType === "hiFatProduct" && !buttermilk

    const isIncorrectProductType = productType === "error"

    const isError = isIncorrectMediumFatProduct || isIncorrectHiFatProduct || isIncorrectProductType

    if (isError) {
      errors.push(
        `${assortment.product.name}, ${currentFatProduct}% (${
          normalizationMethod === "stream" ? "нормализация в потоке" : "объёмная нормализация"
        })`,
      )
      return
    }

    if (productType === "specialType") {
      return
    }

    const { calculateInStream, calculateInVolume, shouldCalculateCheeseAndCottage } =
      getProductTypeConfig(productType, {
        state: localState.dairyProcessingMetrics,
        step2Metrics: localState.step2Metrics,
        _currentFat,
        _currentWeight,
        butterMilkFat,
        creamFat,
        freeFatMilkFat,
        rawMilkFat,
      })

    if (!config.types.includes(productType) || !config.methods.includes(normalizationMethod)) {
      return
    }

    // eslint-disable-next-line no-console
    console.table({
      продукт: assortment.product.name,
      метод: normalizationMethod,
      масса: currentWeight,
      жирность: currentFatProduct,
    })

    if (normalizationMethod === "stream") {
      logCalculateAction(normalizationMethod, productType)
      localState.dairyProcessingMetrics = calculateInStream()
    } else if (normalizationMethod === "volume") {
      logCalculateAction(normalizationMethod, productType)
      // для расчета средней жирности в объеме приходится изменять данные из обоих этапов
      const res = calculateInVolume()
      if ("step2Metrics" in res) {
        localState.step2Metrics = res.step2Metrics
        localState.dairyProcessingMetrics = res.dairyProcessingMetrics
      } else {
        localState.dairyProcessingMetrics = res
      }
    }

    if (shouldCalculateCheeseAndCottage) {
      localState.dairyProcessingMetrics = calculateCheeseAndCottage({
        dairyProcessingMetrics: localState.dairyProcessingMetrics,
        cottageCheeseWhey,
        cheeseWhey,
        currentWeight,
        _currentWeight,
      })
    }
  })

  return localState
}
