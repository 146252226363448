import { useEffect, useMemo, useState } from "react"
import { NavLink } from "react-router-dom"

import { SorterResult } from "antd/es/table/interface"
import { TableProps } from "antd/lib"

import { CaretRightOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { useStore } from "@nanostores/react"
import { Button, Form, InputNumber, Modal, Select, Space, Table, Typography } from "antd"
import { useAllProducts } from "api/endpoints/products/products"
import { ProductDto } from "api/model"
import { rawMaterialCalculator } from "features/rawMaterialCalculator"
import { Assortment, NormalizationMethod } from "features/rawMaterialCalculator/types"
import hash from "object-hash"
import { clientModuleRoutes } from "shared/constants/routes"

import { createColumns } from "./artifacts/createColumns"
import { addAssortment, editAssortment } from "./utils/handleAssortment"

const { Title } = Typography
const { Item } = Form
type TDataType = { normalizationMethod?: NormalizationMethod } & Assortment
type TFormValuesAdd = {
  productIdAdd: number
  normalizationMethodAdd: NormalizationMethod
  currentWeightAdd: number
}
type TFormValuesEdit = {
  productIdEdit: number
  normalizationMethodEdit: NormalizationMethod
  currentWeightEdit: number
}

export const RawMaterialAssortment = () => {
  const assortments = useStore(rawMaterialCalculator.$assortments)
  const { data: getProducts, isSuccess: isProductsLoaded } = useAllProducts()

  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [isOpenDeleteAllConfirmModal, setIsOpenDeleteAllConfirmModal] = useState(false)
  const [changeConfirmModal, setChangeConfirmModal] = useState<{
    desc: string
    confirm: () => void
  }>()
  const [assortmentForDelete, setAssortmentForDelete] = useState<Assortment>()
  const [assortmentEdit, setAssortmentEdit] = useState<TDataType>()
  const [formAdd] = Form.useForm()
  const [formEdit] = Form.useForm()
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TDataType>>({})

  useEffect(() => {
    if (isOpenEdit) return
    formAdd.resetFields()
    formEdit.resetFields()
  }, [isOpenEdit])

  useEffect(() => {
    if (!getProducts) return
    const assortmentsNew: Assortment[] = []
    getProducts?.result.forEach((product) => {
      assortments.forEach((assortment) => {
        if (product.id === assortment.product.id) {
          assortmentsNew.push({
            ...assortment,
            product,
          })
        }
      })
    })

    rawMaterialCalculator.setAssortments(assortmentsNew)
  }, [isProductsLoaded])

  const productsObject = useMemo(() => {
    if (!getProducts?.result) return {}
    const _productsObject: { [key: string]: ProductDto } = {}
    getProducts.result?.forEach((product) => {
      _productsObject[product.id] = product
    })
    return _productsObject
  }, [getProducts?.result])

  const deleteAssortment = () => {
    if (!assortmentForDelete) return

    rawMaterialCalculator.setAssortments([
      ...assortments.filter((obj) => hash(obj) !== hash(assortmentForDelete)),
    ])
    setIsOpenDeleteConfirmModal(false)
  }

  const onDeleteClick = (record: TDataType) => {
    setAssortmentForDelete(record)
    setIsOpenDeleteConfirmModal(true)
  }

  const onEditClick = (record: TDataType) => {
    setAssortmentEdit(record)
    formEdit.setFieldValue("productIdEdit", record.product.id)
    formEdit.setFieldValue("currentWeightEdit", record.currentWeight)
    formEdit.setFieldValue("normalizationMethodEdit", record.normalizationMethod)
    setIsOpenEdit(true)
  }

  const columns = createColumns(sortedInfo, onEditClick, onDeleteClick)

  const onAddFormSubmit = (values: TFormValuesAdd) => {
    addAssortment({ assortments, formAdd, productsObject, setChangeConfirmModal, values })
  }

  const onEditFormSubmit = (values: TFormValuesEdit) => {
    editAssortment({
      assortmentEdit,
      assortments,
      formEdit,
      productsObject,
      setChangeConfirmModal,
      setIsOpenEdit,
      values,
    })
  }

  const productsOptions = useMemo(() => {
    if (!getProducts?.result) return []
    return getProducts?.result.map((product) => {
      return { value: product.id, label: `${product.name} (${product.fatPercentage}%)` }
    })
  }, [getProducts])

  const validatePositiveNumber = (_: unknown, value: number) => {
    if (value === undefined || value <= 0) {
      return Promise.reject("Число должно быть положительным")
    }
    return Promise.resolve()
  }

  const handleChange: TableProps<TDataType>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<TDataType>)
  }

  const normalizationMethodOptions = useMemo(() => {
    return [
      { value: "stream", label: "В потоке" },
      { value: "volume", label: "Объемная" },
    ]
  }, [])

  return (
    <Space direction='vertical'>
      <Space>
        <Title level={2} style={{ marginBottom: 0 }}>
          Ассортимент продуктов
        </Title>
      </Space>
      <Space direction='vertical' style={{ marginTop: 30 }}>
        <Button
          onClick={() => setIsOpenDeleteAllConfirmModal(true)}
          type='primary'
          danger
          disabled={!assortments.length}
          icon={<DeleteOutlined />}
        >
          Удалить все
        </Button>
        <Table
          style={{ maxWidth: 1200 }}
          columns={columns}
          dataSource={assortments}
          onChange={handleChange}
          pagination={false}
          locale={{ emptyText: "Нет данных" }}
        />
      </Space>
      <Form
        layout={"inline"}
        name='assortment'
        form={formAdd}
        style={{ marginTop: 20 }}
        onFinish={onAddFormSubmit}
      >
        <Item
          name='productIdAdd'
          label='Продукт'
          rules={[{ required: true, message: "Заполните это поле" }]}
        >
          <Select
            style={{ minWidth: 400 }}
            options={productsOptions}
            placeholder='Выберите продукт'
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            showSearch
          />
        </Item>
        <Item name='normalizationMethodAdd' label='Способ нормализации'>
          <Select
            defaultValue={normalizationMethodOptions[0]}
            defaultActiveFirstOption
            style={{ minWidth: 200 }}
            options={normalizationMethodOptions}
            filterOption={(input, option) => (option?.label ?? "").includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            showSearch
          />
        </Item>
        <Item
          required
          name='currentWeightAdd'
          label='Масса, кг'
          rules={[{ validator: validatePositiveNumber }]}
        >
          <InputNumber
            placeholder='Масса, кг'
            required
            decimalSeparator=','
            precision={2}
            min={0}
          />
        </Item>
        <Button
          style={{ marginTop: 20 }}
          htmlType='submit'
          type='primary'
          icon={<PlusCircleOutlined />}
        >
          Добавить
        </Button>
      </Form>
      <Space style={{ marginTop: 30 }}>
        <NavLink to={clientModuleRoutes.rawMaterials.calculator.root}>
          <Button disabled={!assortments?.length} type='primary' icon={<CaretRightOutlined />}>
            Перейти к расчёту
          </Button>
        </NavLink>
      </Space>
      <Modal
        title='Редактирование ассортимента'
        centered
        open={isOpenEdit}
        onOk={() => setIsOpenEdit(false)}
        onCancel={() => setIsOpenEdit(false)}
        footer={false}
      >
        <Form
          name='assortmentEdit'
          form={formEdit}
          style={{ marginTop: 20 }}
          labelCol={{ span: 9 }}
          onFinish={onEditFormSubmit}
        >
          <Item
            name='productIdEdit'
            label='Продукт'
            rules={[{ required: true, message: "Заполните это поле" }]}
          >
            <Select
              style={{ minWidth: 200 }}
              options={productsOptions}
              placeholder='Выберите продукт'
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              showSearch
            />
          </Item>
          <Item
            name='normalizationMethodEdit'
            label='Способ нормализации'
            // rules={[{ required: true, message: "Заполните это поле" }]}
          >
            <Select
              defaultValue='stream'
              style={{ minWidth: 200 }}
              options={normalizationMethodOptions}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              showSearch
            />
          </Item>
          <Item
            name='currentWeightEdit'
            label='Масса'
            rules={[{ validator: validatePositiveNumber }]}
          >
            <InputNumber placeholder='Масса' required decimalSeparator=',' precision={2} />
          </Item>
          <Space direction={"horizontal"}>
            <Button type='primary' htmlType='submit'>
              Сохранить
            </Button>
          </Space>
        </Form>
      </Modal>
      <Modal
        title='Подтверждение действия'
        centered
        open={isOpenDeleteConfirmModal}
        onOk={() => setIsOpenDeleteConfirmModal(false)}
        onCancel={() => setIsOpenDeleteConfirmModal(false)}
        footer={false}
      >
        <Space direction='vertical'>
          <Title level={5} style={{ marginBottom: 30 }}>
            Вы действительно ходите удалить ассортимент?
          </Title>
          <Space direction='horizontal'>
            <Button
              onClick={() => deleteAssortment()}
              type='primary'
              htmlType='submit'
              danger
              icon={<DeleteOutlined />}
            >
              Удалить
            </Button>
            <Button onClick={() => setIsOpenDeleteConfirmModal(false)} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
      <Modal
        title='Подтверждение действия'
        centered
        open={isOpenDeleteAllConfirmModal}
        onOk={() => setIsOpenDeleteAllConfirmModal(false)}
        onCancel={() => setIsOpenDeleteAllConfirmModal(false)}
        footer={false}
      >
        <Space direction='vertical'>
          <Title level={5} style={{ marginBottom: 30 }}>
            Вы действительно ходите удалить весь ассортимент?
          </Title>
          <Space direction='horizontal'>
            <Button
              onClick={() => {
                rawMaterialCalculator.clearAssortments()
                setIsOpenDeleteAllConfirmModal(false)
              }}
              type='primary'
              htmlType='submit'
              danger
              icon={<DeleteOutlined />}
            >
              Удалить всё
            </Button>
            <Button onClick={() => setIsOpenDeleteAllConfirmModal(false)} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
      <Modal
        title='Подтверждение действия'
        centered
        open={!!changeConfirmModal}
        onOk={() => setChangeConfirmModal(undefined)}
        onCancel={() => setChangeConfirmModal(undefined)}
        footer={false}
      >
        <Space direction='vertical'>
          <Title level={5} style={{ marginBottom: 30 }}>
            {changeConfirmModal?.desc}
          </Title>
          <Space direction='horizontal'>
            <Button
              onClick={() => {
                changeConfirmModal?.confirm()
                setChangeConfirmModal(undefined)
              }}
              type='primary'
              htmlType='submit'
            >
              Подтвердить
            </Button>
            <Button onClick={() => setChangeConfirmModal(undefined)} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
    </Space>
  )
}
