import { DairyProcessingMetrics } from "features/rawMaterialCalculator/types"

/**
 * @description обновления веса сыворотки в состоянии на основе разницы между модифицированным и текущим весом продукта, если это необходимо
 */
export const calculateCheeseWheyEnabled = ({
  state,
  currentWeight,
  _currentWeight,
}: {
  state: DairyProcessingMetrics
  currentWeight: number
  _currentWeight: number
}) => {
  return {
    ...state,
    cheeseWheyWeight: state.cheeseWheyWeight + (_currentWeight - currentWeight),
  }
}
