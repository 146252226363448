import { Assortment, CalculationConfig } from "features/rawMaterialCalculator/types"

import { defaultDairyProcessingMetrics, defaultStep2Metrics } from "../utils/initialMetrics"
import { primaryValidation } from "../validation"
import { correctOrSeparateCreamAndFreeFatMilk } from "./correctOrSeparateCreamAndFreeFatMilk"
import { getProcessedButterMilkData } from "./getProcessedButterMilkData"
import { getProcessedWheyData } from "./getProcessedWheyData"
import { loopCalculation } from "./loopCalculation"
import { setupStep2Metrics } from "./setupStep2Metrics"

function logConfig(config: CalculationConfig, index: number) {
  console.info(
    `цикл ${index + 1} (для продуктов ${JSON.stringify(
      config.types,
    )} cо способами нормализации ${JSON.stringify(config.methods)})`,
  )
}

export function calculateRawMaterials(
  inputData: Record<string, number>,
  assortments: Assortment[],
  loopConfig: CalculationConfig[],
) {
  const { rawMilkQuantity, rawMilkFat, creamFat, freeFatMilkFat: freeFatMilkFatOrig } = inputData

  const stopCalculation = primaryValidation({
    assortments,
    rawMilkQuantity,
    rawMilkFat,
    creamFat,
    freeFatMilkFatOrig,
  })

  if (stopCalculation) {
    throw new Error("ошибка расчета")
  }

  let calculationState = {
    dairyProcessingMetrics: defaultDairyProcessingMetrics,
    step2Metrics: defaultStep2Metrics,
  }

  const errors: string[] = []

  loopConfig.forEach((config, index) => {
    logConfig(config, index)
    calculationState = loopCalculation(
      {
        config,
        assortments,
        dairyProcessingMetrics: calculationState.dairyProcessingMetrics,
        inputData,
        step2Metrics: calculationState.step2Metrics,
        configIndex: index,
      },
      errors,
    )

    const freeFatMilkFat =
      freeFatMilkFatOrig === null || freeFatMilkFatOrig === undefined ? 0 : freeFatMilkFatOrig //МДЖ обрата, %

    calculationState.step2Metrics = setupStep2Metrics(
      calculationState.step2Metrics,
      calculationState.dairyProcessingMetrics,
    )

    calculationState.step2Metrics = correctOrSeparateCreamAndFreeFatMilk(
      calculationState.step2Metrics,
      {
        creamFat,
        freeFatMilkFat,
        rawMilkFat,
      },
    )

    calculationState.dairyProcessingMetrics = {
      ...defaultDairyProcessingMetrics,
      cottageCheeseWheyWeight: calculationState.dairyProcessingMetrics.cottageCheeseWheyWeight,
      cheeseWheyWeight: calculationState.dairyProcessingMetrics.cheeseWheyWeight,
      wheyWeightForWheyDrinksProducts:
        calculationState.dairyProcessingMetrics.wheyWeightForWheyDrinksProducts,
      butterMilkWeightForButterMilkDrinksProducts:
        calculationState.dairyProcessingMetrics.butterMilkWeightForButterMilkDrinksProducts,
    }
  })

  calculationState.dairyProcessingMetrics = getProcessedWheyData(
    calculationState.dairyProcessingMetrics,
    errors,
  )

  calculationState = getProcessedButterMilkData(calculationState, errors)

  return {
    rawMilkNeedWeight: calculationState.step2Metrics.rawMilkNeedWeight,
    rawMilkQuantity: rawMilkQuantity - calculationState.step2Metrics.rawMilkNeedWeight,
    creamOutputWeight: calculationState.step2Metrics.creamOutputWeight,
    creamCurrentWeight: calculationState.step2Metrics.creamCurrentWeight,
    freeFatMilkOutputWeight: calculationState.step2Metrics.freeFatMilkOutputWeight,
    freeFatMilkCurrentWeight: calculationState.step2Metrics.freeFatMilkCurrentWeight,
    butterMilkWeightFromHiFatProducts: calculationState.step2Metrics.butterMilkWeight,
    cheeseWheyWeight: calculationState.dairyProcessingMetrics.cheeseWheyWeight,
    cottageCheeseWheyWeight: calculationState.dairyProcessingMetrics.cottageCheeseWheyWeight,
    errors,
  }
}
