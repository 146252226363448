import {
  DairyProcessingMetrics,
  ProductFatType,
  Step2Metrics,
} from "features/rawMaterialCalculator/types"

import {
  calculateForFreeFatProducts,
  calculateForHiFatProductsInStream,
  calculateForLowFatProductsInStream,
  calculateForLowFatProductsInVolume,
  calculateForMediumFatProductsInStream,
  calculateForMediumFatProductsInVolume,
} from "../calculation"

export const getProductTypeConfig = (
  productFatType: Exclude<ProductFatType, "error" | "specialType">,
  {
    state,
    step2Metrics,
    _currentWeight,
    creamFat,
    _currentFat,
    rawMilkFat,
    freeFatMilkFat,
    butterMilkFat,
  }: {
    state: DairyProcessingMetrics
    step2Metrics: Step2Metrics
    _currentWeight: number
    creamFat: number
    _currentFat: number
    rawMilkFat: number
    freeFatMilkFat: number
    butterMilkFat: number
  },
) => {
  const productConfigByType = {
    freeFatProduct: {
      shouldCalculateCheeseAndCottage: true,
      calculateInStream: () => calculateForFreeFatProducts(state, _currentWeight),
      calculateInVolume: () => calculateForFreeFatProducts(state, _currentWeight),
    },
    lowFatProduct: {
      shouldCalculateCheeseAndCottage: true,
      calculateInStream: () =>
        calculateForLowFatProductsInStream({
          state,
          _currentWeight,
          creamFat,
          _currentFat,
          rawMilkFat,
        }),
      calculateInVolume: () =>
        calculateForLowFatProductsInVolume({
          state,
          _currentFat,
          _currentWeight,
          freeFatMilkFat,
          rawMilkFat,
        }),
    },
    mediumFatProduct: {
      shouldCalculateCheeseAndCottage: true,
      calculateInStream: () =>
        calculateForMediumFatProductsInStream({
          state,
          _currentFat,
          _currentWeight,
          creamFat,
          freeFatMilkFat,
        }),
      calculateInVolume: () =>
        calculateForMediumFatProductsInVolume({
          state,
          step2Metrics,
          _currentFat,
          _currentWeight,
          creamFat,
          freeFatMilkFat,
          rawMilkFat,
        }),
    },
    hiFatProduct: {
      shouldCalculateCheeseAndCottage: false,
      calculateInStream: () =>
        calculateForHiFatProductsInStream({
          state,
          _currentFat,
          _currentWeight,
          butterMilkFat,
          creamFat,
        }),
      calculateInVolume: () =>
        calculateForHiFatProductsInStream({
          state,
          _currentFat,
          _currentWeight,
          butterMilkFat,
          creamFat,
        }),
    },
  }

  return productConfigByType[productFatType]
}
