import { DairyProcessingMetrics } from "features/rawMaterialCalculator/types"

/**
 * @description Расчет сливок и сырого молока при производстве низкожирных продуктов в объеме
 */
export const calculateForLowFatProductsInVolume = ({
  state,
  _currentWeight,
  _currentFat,
  freeFatMilkFat,
  rawMilkFat,
}: {
  state: DairyProcessingMetrics
  _currentWeight: number
  _currentFat: number
  freeFatMilkFat: number
  rawMilkFat: number
}) => {
  const rawMilkWeightIteration =
    (_currentWeight * (_currentFat - freeFatMilkFat)) / (rawMilkFat - freeFatMilkFat)
  const rawMilkWeightForLowFatProducts =
    state.rawMilkWeightForLowFatProducts + rawMilkWeightIteration

  const freeFatVolumeIteration = _currentWeight - rawMilkWeightIteration
  const freeFatMilkVolumeForLowFatProducts =
    state.freeFatMilkVolumeForLowFatProducts + freeFatVolumeIteration

  return {
    ...state,
    rawMilkWeightIteration,
    freeFatVolumeIteration,
    rawMilkWeightForLowFatProducts,
    freeFatMilkVolumeForLowFatProducts,
  }
}
