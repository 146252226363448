import { DairyProcessingMetrics, Step2Metrics } from "../../types"

export const getProcessedButterMilkData = (
  state: {
    dairyProcessingMetrics: DairyProcessingMetrics
    step2Metrics: Step2Metrics
  },
  errors: string[],
) => {
  const processedButterMilkWeight =
    state.step2Metrics.butterMilkWeight -
    state.dairyProcessingMetrics.butterMilkWeightForButterMilkDrinksProducts

  if (processedButterMilkWeight < 0) {
    errors.push("Напитки из пахты")

    return state
  } else {
    return {
      ...state,
      step2Metrics: {
        ...state.step2Metrics,
        butterMilkWeight: processedButterMilkWeight,
      },
    }
  }
}
