import * as assortments from "./assortments"
import * as calculate from "./calculate"
import * as inputData from "./inputData"
import * as modals from "./modals"

export const rawMaterialCalculator = {
  ...assortments,
  ...calculate,
  ...inputData,
  ...modals,
}
