import { Button } from "antd"
import * as XLSX from "xlsx"

export const ExportCSVButton = ({
  dataForExportCSV,
  fileName,
}: {
  dataForExportCSV: Array<Array<string | number>>
  fileName: string
}) => {
  const exportToCSV = () => {
    const ws = XLSX.utils.aoa_to_sheet(dataForExportCSV)
    ws["!cols"] = [{ wch: Math.max(30, 30) }]

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Export")
    XLSX.writeFile(wb, fileName + ".xlsx")
  }

  return <Button onClick={exportToCSV}>Export XLSX</Button>
}
