import { SystemName } from "api/model"
import { ProductFatType, SpecialTypes } from "features/rawMaterialCalculator/types"

import { specialTypes } from "../calculation/constants"

export function getProductType({
  _currentFat,
  creamFat,
  freeFatMilkFat,
  rawMilkFat,
  systemName,
}: {
  _currentFat: number
  freeFatMilkFat: number
  rawMilkFat: number
  creamFat: number
  systemName: SystemName
}): ProductFatType {
  if (specialTypes.includes(systemName as SpecialTypes)) {
    return "specialType"
  }

  if (_currentFat >= creamFat) {
    return "hiFatProduct"
  } else if (_currentFat > rawMilkFat) {
    return "mediumFatProduct"
  } else if (_currentFat > freeFatMilkFat) {
    return "lowFatProduct"
  } else if (_currentFat === freeFatMilkFat) {
    return "freeFatProduct"
  } else {
    return "error"
  }
}
