/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * alfa-l-service-raw-back API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query"
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import type {
  AllMixesParams,
  EmptyResponse,
  MixForm,
  ResponseListMixDto,
  ResponseMixDto,
} from "../../model"
import { customInstance } from "../../mutator/custom-instance"
import type { ErrorType } from "../../mutator/custom-instance"

/**
 * @summary Получить все смеси в алфавитном порядке
 */
export const allMixes = (params?: AllMixesParams, signal?: AbortSignal) => {
  return customInstance<ResponseListMixDto>({ url: `/v1/mixes`, method: "get", params, signal })
}

export const getAllMixesQueryKey = (params?: AllMixesParams) => {
  return [`/v1/mixes`, ...(params ? [params] : [])] as const
}

export const getAllMixesQueryOptions = <
  TData = Awaited<ReturnType<typeof allMixes>>,
  TError = ErrorType<unknown>,
>(
  params?: AllMixesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allMixes>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAllMixesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof allMixes>>> = ({ signal }) =>
    allMixes(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof allMixes>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AllMixesQueryResult = NonNullable<Awaited<ReturnType<typeof allMixes>>>
export type AllMixesQueryError = ErrorType<unknown>

/**
 * @summary Получить все смеси в алфавитном порядке
 */
export const useAllMixes = <
  TData = Awaited<ReturnType<typeof allMixes>>,
  TError = ErrorType<unknown>,
>(
  params?: AllMixesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allMixes>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAllMixesQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Создать смесь (админ)
 */
export const createMix = (mixForm: MixForm) => {
  return customInstance<ResponseMixDto>({
    url: `/v1/mixes`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: mixForm,
  })
}

export const getCreateMixMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createMix>>,
    TError,
    { data: MixForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createMix>>,
  TError,
  { data: MixForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMix>>, { data: MixForm }> = (
    props,
  ) => {
    const { data } = props ?? {}

    return createMix(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateMixMutationResult = NonNullable<Awaited<ReturnType<typeof createMix>>>
export type CreateMixMutationBody = MixForm
export type CreateMixMutationError = ErrorType<void>

/**
 * @summary Создать смесь (админ)
 */
export const useCreateMix = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createMix>>,
    TError,
    { data: MixForm },
    TContext
  >
}) => {
  const mutationOptions = getCreateMixMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Создать копию (админ)
 */
export const createCopyMix = (id: number) => {
  return customInstance<ResponseMixDto>({ url: `/v1/mixes/copy/${id}`, method: "post" })
}

export const getCreateCopyMixMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyMix>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCopyMix>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCopyMix>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {}

    return createCopyMix(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateCopyMixMutationResult = NonNullable<Awaited<ReturnType<typeof createCopyMix>>>

export type CreateCopyMixMutationError = ErrorType<void>

/**
 * @summary Создать копию (админ)
 */
export const useCreateCopyMix = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyMix>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getCreateCopyMixMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Получить смесь по ID
 */
export const getMix = (id: number, signal?: AbortSignal) => {
  return customInstance<ResponseMixDto>({ url: `/v1/mixes/${id}`, method: "get", signal })
}

export const getGetMixQueryKey = (id: number) => {
  return [`/v1/mixes/${id}`] as const
}

export const getGetMixQueryOptions = <
  TData = Awaited<ReturnType<typeof getMix>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMix>>, TError, TData>> },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMixQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMix>>> = ({ signal }) =>
    getMix(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMix>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetMixQueryResult = NonNullable<Awaited<ReturnType<typeof getMix>>>
export type GetMixQueryError = ErrorType<unknown>

/**
 * @summary Получить смесь по ID
 */
export const useGetMix = <TData = Awaited<ReturnType<typeof getMix>>, TError = ErrorType<unknown>>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMix>>, TError, TData>> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMixQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Обновить смесь (админ)
 */
export const updateMix = (id: number, mixForm: MixForm) => {
  return customInstance<ResponseMixDto>({
    url: `/v1/mixes/${id}`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: mixForm,
  })
}

export const getUpdateMixMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMix>>,
    TError,
    { id: number; data: MixForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateMix>>,
  TError,
  { id: number; data: MixForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMix>>,
    { id: number; data: MixForm }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateMix(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateMixMutationResult = NonNullable<Awaited<ReturnType<typeof updateMix>>>
export type UpdateMixMutationBody = MixForm
export type UpdateMixMutationError = ErrorType<void>

/**
 * @summary Обновить смесь (админ)
 */
export const useUpdateMix = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMix>>,
    TError,
    { id: number; data: MixForm },
    TContext
  >
}) => {
  const mutationOptions = getUpdateMixMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Удалить смесь (админ)
 */
export const deleteMix = (id: number) => {
  return customInstance<EmptyResponse>({ url: `/v1/mixes/${id}`, method: "delete" })
}

export const getDeleteMixMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMix>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<Awaited<ReturnType<typeof deleteMix>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMix>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {}

    return deleteMix(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteMixMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMix>>>

export type DeleteMixMutationError = ErrorType<void>

/**
 * @summary Удалить смесь (админ)
 */
export const useDeleteMix = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMix>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getDeleteMixMutationOptions(options)

  return useMutation(mutationOptions)
}
