import { FormInstance } from "antd"
import { ProductDto } from "api/model"
import { rawMaterialCalculator } from "features/rawMaterialCalculator"
import { Assortment, NormalizationMethod } from "features/rawMaterialCalculator/types"

type TFormValuesEdit = {
  productIdEdit: number
  normalizationMethodEdit: NormalizationMethod
  currentWeightEdit: number
}

type TFormValuesAdd = {
  productIdAdd: number
  normalizationMethodAdd: NormalizationMethod
  currentWeightAdd: number
}

type TDataType = { normalizationMethod?: NormalizationMethod } & Assortment

export const editAssortment = ({
  values,
  assortments,
  assortmentEdit,
  productsObject,
  formEdit,
  setChangeConfirmModal,
  setIsOpenEdit,
}: {
  values: TFormValuesEdit
  assortments: Assortment[]
  assortmentEdit?: TDataType
  productsObject: Record<string, ProductDto>
  formEdit: FormInstance<TDataType>
  setChangeConfirmModal: (v?: { desc: string; confirm: () => void }) => void
  setIsOpenEdit: (v: boolean) => void
}) => {
  const assortmentsUpdated = assortments.map((element) => {
    if (
      element.product.id === assortmentEdit?.product.id &&
      element.normalizationMethod === assortmentEdit?.normalizationMethod
    ) {
      return {
        product: productsObject[values.productIdEdit],
        currentWeight: values.currentWeightEdit,
        normalizationMethod: values.normalizationMethodEdit,
      }
    }
    return element
  })

  let isExistingItem = false

  const map = new Map()
  for (const item of assortmentsUpdated) {
    const key = `${item.product.id}-${item.normalizationMethod}`
    const existingItem = map.get(key)

    if (existingItem) {
      isExistingItem = true
      existingItem.currentWeight += item.currentWeight
    } else {
      map.set(key, { ...item })
    }
  }
  const assortmentsNew = Array.from(map.values())
  if (isExistingItem) {
    setChangeConfirmModal({
      desc:
        "Данный продукт уже добавлен. Хотите увеличить его количество на " +
        values.currentWeightEdit +
        " кг?",
      confirm: () => {
        rawMaterialCalculator.setAssortments(assortmentsNew)
        setIsOpenEdit(false)
        setChangeConfirmModal(undefined)
        formEdit.resetFields()
      },
    })
  } else {
    rawMaterialCalculator.setAssortments(assortmentsNew)
    setIsOpenEdit(false)
    formEdit.resetFields()
  }
}

export const addAssortment = ({
  assortments,
  values,
  formAdd,
  setChangeConfirmModal,
  productsObject,
}: {
  values: TFormValuesAdd
  assortments: Assortment[]
  formAdd: FormInstance<TFormValuesAdd>
  setChangeConfirmModal: (v?: { desc: string; confirm: () => void }) => void
  productsObject: Record<string, ProductDto>
}) => {
  const normalizationMethod = values.normalizationMethodAdd || "stream"
  let isEdited
  const assortmentsNew = assortments.map((element) => {
    if (
      element.product.id !== values.productIdAdd ||
      element.normalizationMethod !== normalizationMethod
    ) {
      return element
    }
    isEdited = true
    return {
      ...element,
      currentWeight: values.currentWeightAdd + element.currentWeight,
      normalizationMethod,
    }
  })
  if (isEdited) {
    setChangeConfirmModal({
      desc:
        "Данный продукт уже добавлен. Хотите увеличить его количество на " +
        values.currentWeightAdd +
        " кг?",
      confirm: () => {
        rawMaterialCalculator.setAssortments(assortmentsNew)
        formAdd.resetFields()
      },
    })
  } else {
    rawMaterialCalculator.setAssortments([
      {
        currentWeight: values.currentWeightAdd,
        normalizationMethod,
        product: productsObject[values.productIdAdd],
        type: "rawMaterial",
      },
      ...assortmentsNew,
    ])
    formAdd.resetFields()
  }
}
