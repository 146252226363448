import { useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"

import { Header } from "antd/es/layout/layout"

import { useStore } from "@nanostores/react"
import { Button, Menu, Space } from "antd"
import { auth } from "features/auth/model"
import { adminModuleRoutes, clientModuleRoutes } from "shared/constants/routes"

export const MainHeader = () => {
  const navigate = useNavigate()
  const token = useStore(auth.$token)
  const isAdmin = useStore(auth.$isAdmin)

  const logout = () => {
    auth.logout()
    navigate("/login")
  }

  const isRawMaterialsButtonActive = useMemo(() => {
    return (
      window.location.pathname === clientModuleRoutes.rawMaterials.assortment.root ||
      window.location.pathname === clientModuleRoutes.rawMaterials.calculator.root
    )
  }, [window.location.pathname])

  const isAdminButtonActive = useMemo(() => {
    return !isRawMaterialsButtonActive
  }, [isRawMaterialsButtonActive])

  return (
    <Header
      className='header'
      style={{
        display: "flex",
        paddingLeft: 0,
      }}
    >
      <div className='logo' />
      <Menu theme='dark' mode='horizontal'>
        <Link to={clientModuleRoutes.rawMaterials.assortment.root}>
          <Button
            style={{
              height: 64,
              borderRadius: 0,
              border: 0,
              color: isRawMaterialsButtonActive ? "white" : "gray",
            }}
            type={isRawMaterialsButtonActive ? "primary" : undefined}
            ghost={!isRawMaterialsButtonActive}
          >
            Калькулятор сырья
          </Button>
        </Link>

        {isAdmin && (
          <Link to={adminModuleRoutes["finished-products"].root}>
            <Button
              style={{
                height: 64,
                borderRadius: 0,
                border: 0,
                color: isAdminButtonActive ? "white" : "gray",
              }}
              type={isAdminButtonActive ? "primary" : undefined}
              ghost={!isAdminButtonActive}
            >
              Админ-панель
            </Button>
          </Link>
        )}
      </Menu>

      <Space style={{ justifyContent: "flex-end", width: "100%" }}>
        {token ? (
          <Button onClick={logout}>Выход</Button>
        ) : (
          <Button onClick={auth.openAuthModal}>Вход</Button>
        )}
      </Space>
    </Header>
  )
}
