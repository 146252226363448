import { DairyProcessingMetrics } from "../../types"

export const getProcessedWheyData = (state: DairyProcessingMetrics, errors: string[]) => {
  let cottageCheeseWheyWeight = state.cottageCheeseWheyWeight
  let cheeseWheyWeight = Math.round(state.cheeseWheyWeight)
  const wheyWeightForWheyDrinksProducts = state.wheyWeightForWheyDrinksProducts
  if (wheyWeightForWheyDrinksProducts > cheeseWheyWeight + cottageCheeseWheyWeight) {
    errors.push("Напитки из сыворотки")

    return state
  }

  cottageCheeseWheyWeight -= wheyWeightForWheyDrinksProducts

  if (cottageCheeseWheyWeight >= 0) {
    return {
      ...state,
      cottageCheeseWheyWeight,
      cheeseWheyWeight,
    }
  } else {
    cheeseWheyWeight += cottageCheeseWheyWeight
    cottageCheeseWheyWeight = 0

    return {
      ...state,
      cottageCheeseWheyWeight,
      cheeseWheyWeight,
    }
  }
}
