import { InputData } from "features/rawMaterialCalculator/types"
import { atom } from "nanostores"

const $inputData = atom<InputData>({})

const setInputData = (newInputData: InputData) => {
  $inputData.set(newInputData)
}

const addInputData = (newInputData: InputData) => {
  $inputData.set({ ...$inputData.get(), ...newInputData })
}

const clearInputData = () => {
  $inputData.set({})
}

export { $inputData, setInputData, addInputData, clearInputData }
