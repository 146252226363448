import { useEffect, useMemo, useState } from "react"

import { Form, Input, InputNumber, Modal, Select } from "antd"
import {
  useCreateProduct,
  useDeleteProduct,
  useUpdateProduct,
} from "api/endpoints/products/products"
import { MixDto, ProductForm, ProductTypeDto } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseModalActions } from "shared/ui/BaseModalActions"
import { ConfirmDeleteModal } from "shared/ui/ConfirmDeleteModal"
import { required } from "shared/validators"

type ModalProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  initialData?: Partial<ProductForm & { id: number }>
  id?: number
  clearInitialData: () => void
  productTypes?: ProductTypeDto[]
  mixes?: MixDto[]
  setSelectedTypeId: (value: number) => void
  selectedTypeId?: number
}

export const ProductModal = ({
  clearInitialData,
  isOpen,
  setIsOpen,
  id,
  initialData,
  productTypes = [],
  mixes = [],
  setSelectedTypeId,
  selectedTypeId,
}: ModalProps) => {
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [form] = Form.useForm()

  const createProduct = useCreateProduct()

  const onCreateProductClick = (values: ProductForm) => {
    createProduct.mutate(
      { data: values },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
      },
    )
  }

  const updateProduct = useUpdateProduct()

  const updateProductClick = (values: ProductForm) => {
    updateProduct.mutate(
      { id: Number(id), data: values },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
      },
    )
  }

  const deleteProduct = useDeleteProduct()

  const deleteProductClick = () => {
    deleteProduct.mutate(
      { id: Number(id) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
          setIsOpenDeleteConfirmModal(false)
        },
      },
    )
  }

  const onFinish = (values: ProductForm) => {
    values.name = values.name.trim()
    id ? updateProductClick(values) : onCreateProductClick(values)
  }

  const mixesOptions = mixes?.map((mix) => ({ value: mix.id, label: mix.name })) ?? []

  const productsTypesOptions = useMemo(() => {
    if (!productTypes) return []
    return productTypes.map((productsType) => {
      return { value: productsType.id, label: productsType.name }
    })
  }, [productTypes])

  const productsTypesObject = useMemo(() => {
    const _productsTypesObject: { [key: string]: boolean } = {}
    productTypes?.forEach((productType) => {
      _productsTypesObject[productType.id] = productType.mixConsumption
    })
    return _productsTypesObject
  }, [productTypes])

  useEffect(() => {
    if (isOpen) return
    form.resetFields()
    clearInitialData()
  }, [isOpen])

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [initialData])

  return (
    <>
      <Modal
        title={`${id ? "Редактирование" : "Добавление"} готового продукта`}
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <Form
          name='mixes'
          form={form}
          style={{ marginTop: 20 }}
          labelCol={{ span: 8 }}
          onFinish={onFinish}
        >
          <Form.Item name='name' label='Название' required>
            <Input placeholder='Название' required />
          </Form.Item>
          <Form.Item name='fatPercentage' label='МДЖ %' required>
            <InputNumber
              placeholder='МДЖ'
              required
              decimalSeparator=','
              precision={2}
              max={99.99}
              min={0}
            />
          </Form.Item>
          <Form.Item name='proteinPercentage' label='МДБ %'>
            <InputNumber placeholder='МДБ' decimalSeparator=',' precision={2} max={99.99} />
          </Form.Item>
          <Form.Item name='type' label='Тип продукта' rules={[required]}>
            <Select
              placeholder='Выберите тип продукта'
              style={{ minWidth: 200 }}
              options={productsTypesOptions}
              onSelect={(event) => setSelectedTypeId(event)}
            />
          </Form.Item>

          <Form.Item name='mix' label='Смесь' rules={[required]} required>
            <Select placeholder='Выберите Смесь' style={{ minWidth: 200 }} options={mixesOptions} />
          </Form.Item>

          {selectedTypeId && productsTypesObject[selectedTypeId] && (
            <Form.Item
              rules={[required]}
              name='mixConsumption'
              label='Расход смеси кг / т'
              required
            >
              <InputNumber placeholder='Расход смеси кг / т' decimalSeparator=',' precision={2} />
            </Form.Item>
          )}

          <BaseModalActions
            isDeleteVisible={Boolean(id)}
            onDelete={() => setIsOpenDeleteConfirmModal(true)}
          />
        </Form>
      </Modal>

      <ConfirmDeleteModal
        action={deleteProductClick}
        isOpen={isOpenDeleteConfirmModal}
        setIsOpen={setIsOpenDeleteConfirmModal}
        title='Подтвердите удаление продукта'
      />
    </>
  )
}
