import { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"

import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import { Assortment, NormalizationMethod } from "features/rawMaterialCalculator/types"

type TDataType = { normalizationMethod?: NormalizationMethod } & Assortment

export const createColumns = (
  sortedInfo: SorterResult<TDataType>,
  onEditClick: (record: TDataType) => void,
  onDeleteClick: (record: TDataType) => void,
) => {
  const cols: ColumnsType<TDataType> = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (_, record: TDataType) => {
        return <>{`${record.product.name} (${record.product.fatPercentage}%)`}</>
      },
      sorter: (a, b) => a.product.name.localeCompare(b.product.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Способ нормализации",
      dataIndex: "normalizationMethod",
      key: "normalizationMethod",
      render: (_, record: TDataType) => {
        return <>{record.normalizationMethod === "stream" ? "В потоке" : "Объемная"}</>
      },
      width: 300,
    },
    {
      title: "Масса",
      dataIndex: "currentWeight",
      key: "currentWeight",
      width: 150,
      sorter: (a, b) => a.currentWeight - b.currentWeight,
      sortOrder: sortedInfo.columnKey === "currentWeight" ? sortedInfo.order : null,
    },
    {
      title: "Действие",
      key: "action",
      width: 300,
      render: (_, record: TDataType) => {
        return (
          <Space size='middle'>
            <Button
              onClick={() => {
                onEditClick(record)
              }}
              type='primary'
              icon={<EditOutlined />}
            >
              Изменить
            </Button>
            <Button
              onClick={() => {
                onDeleteClick(record)
              }}
              type='primary'
              danger
              icon={<DeleteOutlined />}
            >
              Удалить
            </Button>
          </Space>
        )
      },
    },
  ]

  return cols
}
